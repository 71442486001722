export class ReportAnalyzer {
  constructor (report, userId = null) {
    this.report = report
    this.stats = userId
      ? report.stats.filter(stat => stat.id === userId)
      : report.stats
  }

  getUniqueUsers = () => {
    const uniqueNamesAndIds = []
    const seenIds = new Set()
    for (const obj of this.stats) {
      if (!seenIds.has(obj.id)) {
        uniqueNamesAndIds.push({
          name: obj.name,
          id: obj.id
        })
        seenIds.add(obj.id)
      }
    }
    return uniqueNamesAndIds
  }

  getUniqueSprints = () => {
    const uniqueNamesAndIds = []
    const seenIds = new Set()
    for (const obj of this.stats) {
      if (!seenIds.has(obj.sprintId)) {
        uniqueNamesAndIds.push({
          name: obj.sprintName,
          id: obj.sprintId,
          startDate: obj.startDate,
          endDate: obj.endDate
        })
        seenIds.add(obj.sprintId)
      }
    }
    return uniqueNamesAndIds
  }

  getUserStatsForSprint = (sprintId, userId) => {
    for (const stat of this.stats) {
      if (stat.sprintId == sprintId && stat.id == userId) {
        return stat
      }
    }
    throw Error(
      `User stats for user id ${userId} could not be found in sprint ${sprintId}`
    )
  }

  getByUser = () => {
    const sprints = this.getUniqueSprints()
    const users = this.report.users
    for (const sprint of sprints) {
      sprint.userStats = []
      for (const user of users) {
        const stats = this.getUserStatsForSprint(sprint.id, user.id)
        sprint.userStats.push(stats)
      }
    }
    return sprints
  }

  getTotalByProperty = (property, numSprints) => {
    let count = 0

    if (this.stats.length) {
      const sprints = numSprints ? this.stats.slice(-numSprints) : this.stats
      for (const sprint of sprints) {
        if (sprint[property]) {
          count += parseInt(sprint[property]) ? parseInt(sprint[property]) : 0
        }
      }
    }
    return count
  }

  getAverageDelivered = numSprints => {
    if (this.stats.length) {
      const sprints = numSprints ? this.stats.slice(-numSprints) : this.stats

      const deliveredPercent = sprints.map(sprint => {
        return Math.floor(
          (parseInt(sprint.resolvedPoints) / parseInt(sprint.startPoints)) * 100
        )
      })

      const averageDeliveredPercent = Math.floor(
        deliveredPercent.reduce((a, b) => a + b) / deliveredPercent.length
      )

      return averageDeliveredPercent
    } else {
      return 0
    }
  }

  getAverageByField = (field, numSprints) => {
    if (this.stats.length) {
      const count = this.getTotalByProperty(field, numSprints)
      if (count) {
        return parseFloat(count / numSprints).toFixed(2)
      } else {
        return count
      }
    } else {
      return 0
    }
  }
}
